import styled from 'styled-components';
import { Space, Typography, Table } from 'antd';
import { blueDark01, blueDark02 } from '../../../styles/colors';

export const CustomTableTitle = styled(Space)`
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
`;

export const TableItemCount = styled(Typography.Text)`
    font-weight: bolder;
`;

export const BottomPaginationContainer = styled(Space)`
    justify-content: flex-end;
    align-items: right;
    margin-top: 20px;
    width: 100%;
    &.hide {
        display: none;
    }
`;

export const PaginationContainer = styled('div')`
    input {
        height: 32px;
        font-weight: normal;
        font-family: 'Proxima N W15 Bold';
        font-size: 13px;
        line-height: 16px;
        color: ${blueDark01};
        padding: 8px 13px;
    }
`;

export const CustomizedTable = styled(Table)`
    [aria-label] {
        .ant-table-column-sorter-down {
            visibility: visible;
            color: ${blueDark02};
            font-weight: 900;
            font-size: 10px;
            position: relative;
            top: -3px;
        }
    }

    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
        visibility: visible;
        position: relative;
        font-weight: 900;
        font-size: 10px;
    }

    .ant-table-column-sorter-up.active {
        top: 3px;
    }

    .ant-table-column-sorter-down.active {
        top: -3px;
    }

    .ant-table-column-sorter-up,
    .ant-table-column-sorter-down {
        visibility: hidden;
    }

    .ant-table-column-title {
        flex: none;
    }

    .ant-table-column-sorters {
        justify-content: normal;
    }
`;

export const CustomTableSpace = styled(Space)`
    margin: 40px 0 30px 0;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`;
