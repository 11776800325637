import { Space } from 'antd';
import React from 'react';
import { CustomTableProps, CustomColumnType } from './CustomTable.types';
import { BaseUserTableFields } from '../../../models/user/user';
import {
    BottomPaginationContainer,
    CustomizedTable,
    CustomTableSpace,
    PaginationContainer,
} from './CustomTable.styles';
import CustomPagination from '../pagination/CustomPagination';
import ChangePageSize from '../pagination/ChangePageSize';
import { SpaceEnd } from '../../../styles/common.styles';

const CustomTable: React.FC<CustomTableProps> = ({
    data,
    columns,
    rowSelection,
    tableData,
    totalCount = 0,
    searchComponent,
    locale,
    changePageSize,
    handleCurrentPage,
    changePageNumber,
    changeSort,
    clickRow,
}) => {
    const handleTableChange = (_pagination: any, _filter: any, sorter: any) => {
        changeSort(sorter.field, sorter.order);
    };

    columns.forEach((column: CustomColumnType<BaseUserTableFields>) => {
        if (column.clickable) {
            column.onCell = (record) => ({
                onClick: () => clickRow(record),
            });
        }
    });

    return (
        <>
            <CustomTableSpace direction="horizontal">
                <Space direction="vertical" size={16}>
                    {searchComponent}
                </Space>
                <SpaceEnd>
                    <ChangePageSize changePageSize={changePageSize} pageSize={tableData.pageSize} />
                    <PaginationContainer style={{marginLeft:16+`px`}}>
                        <CustomPagination
                            page={tableData.curPage}
                            total={totalCount}
                            pageSize={tableData.pageSize}
                            changePageNumber={changePageNumber}
                            handleCurrentPage={handleCurrentPage}
                        />
                    </PaginationContainer>
                </SpaceEnd>
            </CustomTableSpace>
            <div className={`antd-table ${data?.length ? '' : 'empty'}`}>
                <CustomizedTable
                    pagination={{ pageSize: tableData.pageSize }}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={data}
                    onChange={handleTableChange}
                    locale={locale}
                />
            </div>
            <BottomPaginationContainer
                direction="horizontal"
                className={`${data.length ? '' : 'hide'}`}
            >
                <PaginationContainer>
                    <CustomPagination
                        page={tableData.curPage}
                        total={totalCount}
                        changePageNumber={changePageNumber}
                        handleCurrentPage={handleCurrentPage}
                        pageSize={tableData.pageSize}
                    />
                </PaginationContainer>
            </BottomPaginationContainer>
        </>
    );
};

export default CustomTable;
